<template>
    <page :title="'机构详情'">
        <div>
          <commonbanner></commonbanner>
            <div>
            <article id="page-expert">
            <section class="container container-main mt-4 mb-4">
              <Collection :name="`provider/${id}`"  v-slot="{ model }">
              <div class="orgdetails">
              <div class="orgdetails-header">
                <h4 class="orgdetails-title">机构信息</h4>
                <div class="tel">联系电话: <span>{{ model.phone }}</span></div>
              </div>
                <div class="orgdetails-body clearfix">
                  <div class="lawwer-staff-wrap">
                    <div class="pic">
                      <div rowspan="6" class="org_pic">
                        <img :src="getOrgPhotoURL(model.provider_id, model.cover)">
                      </div>
                    </div>
                    <div class="content-box">
                      <h3>{{ model.provider_name }}</h3>
                      <div class="pull-left sm"> <span class="tit">执业年限：</span> <span class="cont tex-name"> <i>−</i> </span> </div>
                      <div class="pull-left"> <span class="tit">业务专长：</span> <span class="cont"> <b class="b-tag">{{ model.org_professional }}</b> </span> </div>
                      <div class="pull-left sm"> <span class="tit">执业证号：</span> <span class="cont tex-name">{{ model.practice_code }}</span> </div>
                      <div class="pull-left"> <span class="tit">联系地址：</span> <span class="cont">{{ model.address }}</span> </div>
                    </div>
                  </div>
                </div>
                <div class="staff-info pb-5">
                  <div class="tab">
                    <ul class="tab-head clearfix" id="tab-affix">
                      <li><a href="#info01" class="">执业信息</a></li>
                      <li><a href="#info02" class="">服务记录</a></li>
                      <li><a href="#info03">机构人员</a></li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-section" id="info01">
                        <h4 class="head">执业信息</h4>
                        <div class="body">
                          <ul class="info-list clearfix">
                            <li>
                              <label class="name">负责人：</label>
                              {{ model.leader }}</li>
                            <li>
                              <label class="name">地址：</label>
                              {{ model.address }}</li>
                            <li>
                              <label class="name">执业许可证号：</label>
                              {{ model.practice_code }}
                            </li>
                            <li>
                              <label class="name">执业状态：</label>
                              {{ model.status }}</li>
                            <li>
                              <label class="name">组织机构代码证：</label>
                            </li>
                            <li>
                              <label class="name">批准文号：</label>
                              </li>
                            <li>
                              <label class="name">批准日期：</label>
                              {{ model.certificate_date }}</li>
                            <li>
                              <label class="name">主管机关：</label>
                            </li>
                            <li>
                              <label class="name">联系电话：</label>
                              {{ model.phone }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="tab-section pr200" id="info02">
                        <h4 class="head">服务记录</h4>
                        <Collection name="/workorder" v-slot="{ collection }">
                        <div class="body">
                          <table class="table table-striped table-bordered" border="0">
                            <thead>
                            <tr>
                              <th>类型</th>
                              <th>咨询人</th>
                              <th>时间</th>
                              <th>状态</th>
                              <th>评价</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in collection" :key="item.workorder_id">
                                  <th>热线咨询</th>
                                  <th>{{ showPerson(item.sponsor_name) }}</th>
                                  <th>{{ showTimeForpage(item.apply_time) }}</th>
                                  <th>{{ item.status }}</th>
                                  <!-- <th>{{ item.workorder_assess }}</th> -->
                                  <th>满意</th>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                        </Collection>
                      </div>
                      <div class="tab-section pr200" id="info03">
                        <h4 class="head">机构人员</h4>
                        <div class="body">
                          <dl class="member-list">
                            <dt>机构人员:</dt>
                            <dd>
                              <ul class="clearfix" v-for="value in person" :key="value.person_id">
                                <li>
                                  <router-link :to="`/persondetails/${value.person_id}`">{{ value.person_name }}</router-link>
                                </li>
                              </ul>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Collection>
            </section>
            </article>
        </div>
    </div>
    </page>
</template>
<script>
import Collection from '@/components/Collection'
import Commonbanner from '@/components/base/Commonbanner'
import model from '../../core/model.js'
export default {
  name: 'PersonDetails',
  mixins: [model],
  components: {
    Commonbanner,
    Collection
  },
  mounted () {
    this.personList()
    this.workorderList()
    this.orgZan()
  },
  data () {
    return {
      id: this.$route.params.id,
      person: [],
      workorder: [],
      formbase: {
        host_id: '',
        host_type: ''
      },
      zanNum: 0
    }
  },
  methods: {
    personList () {
      this.$api.get('/api/person', {
        params: {
          provider_id: this.id
        }
      }).then((res) => {
        this.person = res.data.collection
      })
    },
    workorderList () {
      this.$api.get('/api/workorder').then((res) => {
        this.workorder = res.data.collection
      })
    },
    // 点赞判断登录
    zanLogin (url) {
      var that = this
      var newUrl = url + '/' + that.id
      if (that.$Cookies.get('userData') === '' || that.$Cookies.get('userData') === undefined) {
        that.$modal.confirm({
          content: h => <div>当前操作需要登录后进行</div>,
          okText: '去登录',
          cancelText: '返回',
          onOk () {
            that.$Cookies.set('gopath', newUrl)
            that.$router.push('/login')
          },
          onCancel () {
          }
        })
      } else {
        that.$message.success('点赞成功')
        var formdata = that.formbase
        formdata.host_id = that.id
        formdata.host_type = 'provider'
        var res = that.saveData({ url: '/praise', data: formdata })
        if (res) {
          that.zanNum = that.zanNum + 1
        }
      }
    },
    orgZan () {
      this.$api.get('/api/provider', {
        params: {
          provider_id: this.id
        }
      }).then((res) => {
        this.zanNum = res.data.collection[0].like_num
      })
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id
    }
  }
}
</script>
<style scoped src="../../assets/css/orgdetails.css"></style>
